import React, { useCallback, useEffect, useState } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/styles/withStyles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useApplicationStyles } from './Application.styles';
import OverflowTooltip from './OverflowTooltip';
import { TRUSTED_URL } from '../constants';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { errorToMessage } from '../utils/errorUtil';
import { TApplication } from 'src/redux/userSlice';
import { refillBalance, fetchAppByLicense } from '../requests/applications';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/rootReducer';
import { Product } from 'server/modules/general/dto/get-app-settings.dto';
import { ProductCodes } from 'server/modules/license/dto/create-license.dto';

type TApplicationProps = {
  app: TApplication;
  products: Product[];
};

const ExpansionPanelStyled = withStyles({
  root: {
    '&$expanded': {
      boxShadow: '8px 8px 8px rgba(0,0,0,0.2)',
    },
  },
  expanded: {},
})(ExpansionPanel);

const ExpansionPanelSummaryStyled = withStyles({
  root: {
    padding: '0 16px 0 16px',
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(ExpansionPanelSummary);

const mapStateToProps = (state: RootState) => ({
  products: state.appSettings.products,
});

const ApplicationComponent: React.FC<TApplicationProps> = ({ app, products }) => {
  const styles = useApplicationStyles();
  // const { appName, description, lastOperation, entityId, type, hostUri, clientId, appImgUrl } = app;
  const { appName, description, appImgUrl, clientId } = app;
  const [toast, setToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [balance, setBalance] = useState<{ key: string; value: string }[]>([]);
  const [license, setLicense] = useState<string>('');
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const getAppByLicense = useCallback(async () => {
    if (isExpanded) {
      try {
        const response = await fetchAppByLicense(clientId);
        setBalance(response.data.balance);
      } catch (e) {
        if (e instanceof Error) {
          const message = errorToMessage(e);
          setToastMessage(message);
          setToast((prevState) => !prevState);
        }
      }
    }
  }, [clientId, isExpanded]);

  useEffect(() => {
    const getApps = async () => {
      getAppByLicense();
    };

    getApps();
  }, [getAppByLicense]);

  const handleRefillClick = async (e: any) => {
    e.stopPropagation();
    try {
      await refillBalance(license, clientId);
      getAppByLicense();
    } catch (e) {
      if (e instanceof Error) {
        const message = errorToMessage(e);
        setToastMessage(message);
        setToast(!toast);
      }
    }
  };

  const closeSnackbar = () => {
    setToast(false);
  };

  return (
    <ExpansionPanelStyled expanded={isExpanded} onClick={() => {}}>
      <ExpansionPanelSummaryStyled onClick={() => setIsExpanded(!isExpanded)}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={10}>
            <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
              <Avatar
                src={TRUSTED_URL + appImgUrl}
                imgProps={{
                  onError: (e): void => {
                    e.currentTarget.src = '/logo192.png';
                  },
                }}
              />
              <Box ml={2.5} className={styles.titleContainer}>
                <OverflowTooltip title={appName}>
                  <Typography variant="body1">{appName}</Typography>
                </OverflowTooltip>
                {description && (
                  <OverflowTooltip title={description}>
                    <Typography variant="body2" color="textSecondary">
                      {description}
                    </Typography>
                  </OverflowTooltip>
                )}
              </Box>
            </Grid>
          </Grid>
          {/* <Grid item xs={1}>
            <Typography variant="body2">{entityId}</Typography>
          </Grid> */}
        </Grid>
      </ExpansionPanelSummaryStyled>
      <Box className={styles.boxStyles}>
        <Box>
          <TextField
            className={styles.licenseInput}
            label="Лицензия"
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              typeof e?.target?.value === 'string' && setLicense(e.target.value);
            }}
          />
        </Box>
        <div className={styles.balanceBlock}>
          <Typography className={styles.label}>Баланс</Typography>
          {!!products?.length &&
            products.map(
              (product) =>
                !!product?.id && (
                  <Typography key={product.id} className={styles.data}>
                    {product.product_name} :{' '}
                    {
                      balance.find(
                        (b) =>
                          (b.key as unknown as ProductCodes) ===
                          (product.product_code as unknown as ProductCodes),
                      )?.value
                    }
                  </Typography>
                ),
            )}
        </div>
        <Box className={styles.buttonBlock}>
          <Button color="primary" className={styles.button} onClick={handleRefillClick}>
            Пополнить баланс
          </Button>
        </Box>
      </Box>
      <Snackbar open={toast} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="error">
          {toastMessage}
        </Alert>
      </Snackbar>
    </ExpansionPanelStyled>
  );
};

export const Application = connect(mapStateToProps)(ApplicationComponent);
