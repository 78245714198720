import { ILicenseResponse, IMessage } from '../interface';
import { BACKEND_URL } from '../constants';
import { getAccessToken } from '../service/authService';
import { request } from '../utils/requestUtil';
import { ResponseError } from '../interface';
import { GetCodeInfoDTO } from 'server/modules/code/dto/get-code-info.dto';
import { CreateCodeDTO } from 'server/modules/code/dto/create-code.dto';
import { CreateLicenseDTO } from 'server/modules/license/dto/create-license.dto';
import { LicenseDTO } from 'server/modules/license/dto/license.dto';

export const getActivationCodeInfo = async (
  activationCode: string,
): Promise<IMessage<GetCodeInfoDTO>> => {
  const accessToken = await getAccessToken();

  return await request<GetCodeInfoDTO>('GET', BACKEND_URL + '/api/v1/code/' + activationCode, {
    Authorization: 'Bearer ' + accessToken,
  });
};

export const checkLicense = async (license: string): Promise<IMessage<ILicenseResponse>> => {
  const accessToken = await getAccessToken();

  return await request<ILicenseResponse>('GET', BACKEND_URL + '/api/v1/license/' + license, {
    Authorization: 'Bearer ' + accessToken,
  });
};

export const getLicenseInfo = async (license: string): Promise<IMessage<LicenseDTO>> => {
  const accessToken = await getAccessToken();

  return await request<LicenseDTO>('GET', BACKEND_URL + '/api/v1/license/' + license + '/info', {
    Authorization: 'Bearer ' + accessToken,
  });
};

export const createActivationCode = async (params: CreateCodeDTO): Promise<string[]> => {
  try {
    const accessToken = await getAccessToken();

    const response = await fetch(BACKEND_URL + '/api/v1/code', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });

    if (!response?.ok)
      return Promise.reject(new ResponseError(response.status, response.statusText));

    const json = await response.json();
    const result = json.data.map((code: string) => code).join(', \r\n');

    return result;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getLicenseByActivationCode = async (
  license: string,
): Promise<IMessage<ILicenseResponse>> => {
  const accessToken = await getAccessToken();

  return await request<ILicenseResponse>('GET', BACKEND_URL + '/api/v1/license/' + license, {
    Authorization: 'Bearer ' + accessToken,
  });
};

export const createLicense = async (params: CreateLicenseDTO): Promise<string[]> => {
  try {
    const accessToken = await getAccessToken();

    const response = await fetch(BACKEND_URL + '/api/v1/license/create', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });

    if (!response?.ok)
      return Promise.reject(new ResponseError(response.status, response.statusText));

    const json = await response.json();
    const result = json.data.map((code: string) => code).join(', \r\n');

    return result;
  } catch (e) {
    return Promise.reject(e);
  }
};
