import { LicenseTypes } from '../../server/node_modules/.prisma/client';
import { AUTH_SCHEME } from './constants';
import { Roles, TUserSlice } from './redux/userSlice';
import { sendAuthRequest } from './requests/oidc';
import { showWidget } from './service/authService';

export const convertLicenseType = (licenseType?: LicenseTypes) => {
  switch (licenseType) {
    case 'ANNUAL':
      return 'Годовая';
    case 'DAILY':
      return 'Суточная';
    case 'PERPETUAL':
      return 'Бессрочная';
    case 'TRIAL':
      return 'Пробная';
    default:
      return null;
  }
};

export const isManager = (role: TUserSlice['userRole']) =>
  role === Roles.EDITOR || role === 'manager';

export const handleLoginClick = () => {
  if (AUTH_SCHEME === 'new') sendAuthRequest('consent');
  else showWidget();
};
