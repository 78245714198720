import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useGenerateKeyStyles = makeStyles((theme) =>
  createStyles({
    body: {
      width: 640,
      paddingTop: 55,
    },
    text: {
      fontSize: '1.5rem',
      display: 'flex',
      padding: '8px 0',
      color: theme.palette.secondary.main,
      alignItems: 'center',
    },
    radioGroup: {
      padding: '8px 0',
      flexDirection: 'row !important' as any,
    },
    radio: {
      padding: 16,
      color: '#2196F3 !important',
    },
    menuPadding: {
      '& .MuiList-padding': {
        padding: 0,
      },
    },
    formControl: {
      padding: '8px 0 !important',
    },
    textField: { width: '100%', padding: '8px 0' },
    select: { width: '100%' },
    menu: { color: '#9e9e9e', justifyContent: 'center' },
    button: { color: '#9e9e9e' },
    sendButton: { width: 186, height: 36, backgroundColor: '#2196F3', color: '#FFF' },
    buttonBox: {
      display: 'flex',
      padding: 16,
      justifyContent: 'center',
    },
    textarea: {
      width: '100%',
      minHeight: 120,
      resize: 'none',
      backgroundColor: '#E3F2FD',
    },
    underline: {
      '&&&:before': {
        borderBottom: '1px solid #2196F3',
      },
      '&&:after': {
        borderBottom: '1px solid #2196F3',
      },
    },
  }),
);
